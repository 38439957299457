html{
  height: 100%
}

body.login{
  background: url(images/bg.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

body{
  padding-top: 70px;
}

hr{
  border-color: grey;
  border-width: 2px;
}

ul{
  padding-left: 0px;
}

textarea{
  resize: none;
}

input[readonly].form-control{
  background-color: white;
}

.container{
  padding-bottom: 20px;
}

.progress-bar{
  min-width: 2em;
}

.click-table:hover{
  cursor: pointer;
}

.pull-bottom{
  position: fixed;
  bottom: 0px;
  width: 100%;
}

.nopadding {
   padding: 7px !important;
   font-size: 12px;
}

[v-cloak] {
  display: none;
}

.pace {
  -webkit-pointer-events: none;
  pointer-events: none;

  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.pace-inactive {
  display: none;
}

.pace .pace-progress {
  background: #29d;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 2px;
}

th {
  cursor: pointer;
}

.glyphicon.glyphicon-triangle-top {
  font-size: 10px;
}

.glyphicon.glyphicon-triangle-bottom {
  font-size: 10px;
}